<template>
  <MetaBadgeList class="vacancy-item__meta">
    <MetaBadge v-if="busynessName" primary><IconBriefcase class="icon" />{{ busynessName }}</MetaBadge>
    <MetaBadge primary><IconCashBanknote class="icon" />{{ payment }}</MetaBadge>
  </MetaBadgeList>
</template>

<script setup>
import { IconBriefcase, IconCashBanknote } from '@tabler/icons-vue'

const props = defineProps({
  vacancy: Object
})

const busynessName = computed(
  () => ({
    full: 'Полная занятость',
    part: 'Частичная занятость'
  })[props.vacancy.busyness]
)
const payment = computed(() => {
  if (props.vacancy.payment === null) return 'Договорная'
  if (props.vacancy.payment === 0) return 'Безвоздмездно'
  return `${props.vacancy.payment}₽` + ({
    hour: '/ч.',
    month: '/мес.'
  }[props.vacancy.payment_type] ?? '')
})
</script>

<style scoped lang="scss">

</style>
